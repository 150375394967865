import Image from "next/image";
import { getImageDimensions } from "@sanity/asset-utils";
import { urlForImage } from "@/lib/sanity";

export const getIconImage = (icon, iconWidth = "18px") => {
  if (icon?.asset) {
    const { width, height } = getImageDimensions(icon);
    return (
      <div className={`w-[${iconWidth}] h-auto`}>
        <Image
          width={width}
          height={height}
          alt="icon"
          src={urlForImage(icon).url()}
          layout="responsive"
        />
      </div>
    );
  }
  return null;
};

export const getUserID = (session) => {
  return session.provider === "credentials"
    ? session.user_id
    : `${session.provider}-${session.user_id}`;
};

export const getUserIDForToken = (token) => {
  if (token) {
    return token.provider === "credentials"
      ? token.sub
      : `${token.provider}-${token.sub}`;
  }
};

export const scrollToFirstInvalidInput = (parentId) => {
  setTimeout(() => {
    const parentElem = document.getElementById(parentId);
    const invalidInput = parentElem.querySelector(".border-error-red");
    invalidInput?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, 200);
};
