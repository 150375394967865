import mixpanel, { Dict, Query } from "mixpanel-browser";

const sendMixpanelEvents = process.env.NEXT_PUBLIC_SEND_MIXPANEL_EVENTS === "1";

export const Mixpanel = {
  identify: (id: string) => {
    if (!sendMixpanelEvents) return;
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (!sendMixpanelEvents) return;
    mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    if (!sendMixpanelEvents) return;
    mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    if (!sendMixpanelEvents) return;
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict) => {
      if (!sendMixpanelEvents) return;
      mixpanel.people.set(props);
    },
  },
  reset: () => {
    if (!sendMixpanelEvents) return;
    mixpanel.reset();
  },
};
