import { createContext, useContext, useState } from "react";

const AppContext = createContext();

export function AppWrapper({ children }) {
  const [cart, setCart] = useState([]);
  const [ordersToCheckout, setOrdersToCheckout] = useState([]);
  const [quoteFormData, setQuoteFormData] = useState({});
  const [isCrispReady, setCrispReady] = useState(false);
  const [numDraftsSelected, setNumDraftsSelected] = useState(0);

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        ordersToCheckout,
        setOrdersToCheckout,
        quoteFormData,
        setQuoteFormData,
        isCrispReady,
        setCrispReady,
        numDraftsSelected,
        setNumDraftsSelected,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
export function useAppContext() {
  return useContext(AppContext);
}
